/* eslint-disable func-names */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React from 'react';
// import cookie from 'cookie';
// import { ConfigProvider } from 'antd';
import getConfig from 'next/config';
// import viVN from 'antd/lib/locale/vi_VN';
import { isClient } from '@/utils/helpers';
import dynamic from 'next/dynamic';
import HeadLayout from '@/componentWebs/headLayout';
import $ from 'jquery';

// const QuickMenu = dynamic(() => import('@/componentWebs/QuickMenu'), {
//   ssr: false,
//   loading: () => null,
// });

const { publicRuntimeConfig } = getConfig();
// const styleQuickMenu = `${publicRuntimeConfig.DOMAIN_STATIC}/static/web/css/styleQuickMenu.css`;
const owlcarousel = publicRuntimeConfig.DOMAIN_STATIC + '/static/web/css/owl.carousel.css';
const owlthemedefault = publicRuntimeConfig.DOMAIN_STATIC + '/static/web/css/owl.theme.default.css';
const bootstrap =
  publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/bootstrap-grid.css';
const ckeditor = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/ckeditor.css';
const reset = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/reset.css';
const base = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/base.css';
const main = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/main.css';
const minh = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/minh.css';
const customCss = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/customCss.css';

const responsive = publicRuntimeConfig.DOMAIN_STATIC + '/static/vietnamTickets/css/responsive.css';
const fontawesome5 = publicRuntimeConfig.DOMAIN_STATIC + '/static/web/css/fontawesome5/css/all.css';

// const styleQuickMenu = `${publicRuntimeConfig.DOMAIN_STATIC}/static/web/css/styleQuickMenu.css`;

const Header = dynamic(() => import(`@/componentWebs/vietnamTickets/Header`), {
  ssr: true,
  loading: () => null,
});
// const Cart = dynamic(() => import('@/componentWebs/mew_nature/Cart'), {
//   ssr: true,
//   loading: () => null,
// });
const Footer = dynamic(() => import(`@/componentWebs/vietnamTickets/Footer`), {
  ssr: true,
  loading: () => null,
});
const QuickMenu = dynamic(() => import('@/componentWebs/QuickMenu'), {
  ssr: true,
  loading: () => null,
});

if (isClient) {
  require('@/static/web/js/lazysizes.min.js');
  require('@/static/web/js/owl.carousel.js');
}

export default class Index extends React.Component {
  render() {
    const {
      menuHeader,
      menuFooter,
      cookies,
      menuLeft,
      dataSlide,
      dataSite,
      advertisments,
      children,
      asPath,
    } = this.props;

    const isCheckout = asPath.split('?')[0] === '/checkout';

    return (
      <React.Fragment>
        <HeadLayout>
          <link rel="stylesheet" href={bootstrap} type="text/css" />
          <link rel="preload" href={bootstrap} as="style" />

          <link rel="stylesheet" href={reset} type="text/css" />
          <link rel="preload" href={reset} as="style" />
          <link rel="stylesheet" href={base} type="text/css" />
          <link rel="preload" href={base} as="style" />
          <link rel="stylesheet" href={main} type="text/css" />
          <link rel="preload" href={main} as="style" />
          <link rel="stylesheet" href={minh} type="text/css" />
          <link rel="preload" href={minh} as="style" />
          <link rel="stylesheet" href={customCss} type="text/css" />
          <link rel="preload" href={customCss} as="style" />
          {/* <link rel="stylesheet" href={styleQuickMenu} /> */}
          <link rel="stylesheet" href={responsive} type="text/css" />
          <link rel="preload" href={responsive} as="style" />
          <link rel="stylesheet" href={fontawesome5} type="text/css" />
          <link rel="preload" href={fontawesome5} as="style" />
          <link rel="stylesheet" href={owlcarousel} type="text/css" />
          <link rel="preload" href={owlcarousel} as="style" />
          <link rel="stylesheet" href={owlthemedefault} type="text/css" />
          <link rel="preload" href={owlthemedefault} as="style" />
          {/* <link rel="stylesheet" href={styleQuickMenu} /> */}
          <link rel="stylesheet" href={responsive} type="text/css" />
          <link rel="preload" href={responsive} as="style" />
          <link rel="stylesheet" href={ckeditor} type="text/css" />
          <link rel="preload" href={ckeditor} as="style" />

          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap"
            rel="stylesheet"
            as="font" crossorigin
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
            rel="stylesheet"
            as="font" crossorigin
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;1,800&display=swap"
            rel="stylesheet"
            as="font" crossorigin
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Krub:wght@300;400;500;700&display=swap"
            rel="stylesheet"
            as="font" crossorigin
          />
        </HeadLayout>
        {/* <Cart dataSite={dataSite} /> */}
        {!isCheckout && (
          <Header menuHeader={menuHeader} cookies={cookies} dataSite={dataSite} asPath={asPath} menuLeft={menuLeft} />
        )}
        {children}
        <QuickMenu />
        <Footer menuFooter={menuFooter} dataSite={dataSite} />
      </React.Fragment>
    );
  }
}
